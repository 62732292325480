/* Add button */

.add-button-image {
  width: 120px;
}

label.add-button-label {
  display: flex;
  margin-bottom: 0;
  cursor: pointer;
  pointer-events: all;
}

.stage-wrapper {
  width: 50%;
  display: inline-block;
  float: left;
}

.add-button-label {
  cursor: pointer;
  display: inline;
}

.file-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

input.slider-input {
  display: inline-block;
  width: 50%;
}

.fixed-status-bar {
  height: 40px;
  background: lightgrey;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 3;
  bottom: 0;
}

input.input-small[type="range"] {
  width: 40%;
  display: inline;
}

.dropzone {
  height: 100vh;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.checkerboard-background {
  background-color: #dddddd;
  background-image: linear-gradient(45deg, #aaaaaa 25%, transparent 25%),
    linear-gradient(-45deg, #aaaaaa 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #aaaaaa 75%),
    linear-gradient(-45deg, transparent 75%, #aaaaaa 75%);
  background-size: 40px 40px;
  background-position: 0 0, 0 20px, 20px -20px, -20px 0px;
}

.interactive-ui {
  background: linear-gradient(
    270deg,
    #ff0000,
    #ff7800,
    #41a404,
    #047ca4,
    #3812ed
  );
  background-size: 1000% 1000%;

  -webkit-animation: AnimationName 4s ease-in-out infinite;
  -moz-animation: AnimationName 4s ease-in-out infinite;
  animation: AnimationName 4s ease-in-out infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Taken from https://loading.io/css/ */

.lds-dual-ring {
  display: inline-block;
  width: 24px;
  height: 24px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
